export const SET_PARTNERS_FILTER = 'setPartnersFilter';

export default {
  state: {
    filters: {
      search: '',
      status: null,
      owner: null,
      useAdvancedSearch: false,
      advancedSearch: false,
    },
  },
  mutations: {
    [SET_PARTNERS_FILTER](state, payload) {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
  },
};
