import { Module } from 'vuex-module-decorators';
import { ProductOrder } from '@gid/models';
import {
  CrudList,
  CrudListFilters,
} from '@gid/vue-common/store/shared/crud-list.module';
import { FilterArrayOption } from './product-orders-pending.module';

export const moduleName = 'productOrders';

export interface ProductOrderFilters extends CrudListFilters {
  search: string;
  opportunity: FilterArrayOption[];
  supplier: FilterArrayOption[];
  partner: FilterArrayOption[];
  product: FilterArrayOption[];
}

@Module({ name: moduleName, namespaced: true })
export default class ProductOrdersModule extends CrudList<ProductOrder> {
  crudEndpoint: string = '/documents-api/product-orders';
  filters: ProductOrderFilters = {
    skipPersist: false,
    search: '',
    opportunity: [],
    supplier: [],
    partner: [],
    product: [],
  };
}
