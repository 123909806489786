import Vue from 'vue';
import VueI18 from 'vue-i18n';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import de from 'vee-validate/dist/locale/de.json';

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Add the required rule
extend('required', required);
extend('email', email);

// Custom rules
export function initVeeValidateRulesWithI18n(i18n: VueI18) {
  extend('phone', {
    message: i18n.t('validation.phone') as string,
    validate: (value) => /^[0-9][0-9 +-/()]+$/.test(value),
  });
}

export function setVeeValidateLocale(code: string): void {
  if (code == 'de') {
    localize('de', de);
  } else {
    localize('en', en);
  }
}
