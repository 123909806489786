import moment from 'moment';
import { Module } from 'vuex-module-decorators';
import { AccountingDocument } from '@gid/models';
import {
  CrudList,
  CrudListActionsEnum,
  CrudListFilters,
} from '@gid/vue-common/store/shared/crud-list.module';

export enum _AccountingDocumentsActionsEnum {}
// Add job view specific actions/mutations here

export const AccountingDocumentsActionsEnum = {
  ...CrudListActionsEnum,
  ..._AccountingDocumentsActionsEnum,
};

export interface AccountingDocumentsFilters extends CrudListFilters {
  dateFrom: string;
  dateTo: string;
  docType: string;
  targetType: string;
  reminderStatus: string;
  status: string;
  search: string;
  skipPersist: boolean;
  paymentReminderBlocked: boolean;
}
export const moduleName = 'accountingDocuments';

@Module({ name: moduleName, namespaced: true })
export class AccountingDocumentsModule extends CrudList<AccountingDocument> {
  crudEndpoint: string = '/documents-api/accounting/documents';
  filters: AccountingDocumentsFilters = {
    skipPersist: false,

    dateFrom: moment().startOf('month').toISOString(),
    dateTo: moment().endOf('day').toISOString(),

    docType: '',
    targetType: '',
    reminderStatus: '',
    status: '',
    search: '',
    paymentReminderBlocked: false,
  };
  store?: {
    getters?: {
      access_token?: string;
    };
  };
  // Getters
}

export default AccountingDocumentsModule;
