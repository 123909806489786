import { Module } from 'vuex-module-decorators';

import { AccountingConfiguration } from '@gid/models';
import {
  CrudList,
  CrudListActionsEnum,
} from '@gid/vue-common/store/shared/crud-list.module';

export enum _AccountinConfigurationActionsEnum {}
// Add job view specific actions/mutations here

export const AccountingConfigurationActionsEnum = {
  ...CrudListActionsEnum,
  ..._AccountinConfigurationActionsEnum,
};

export const moduleName = 'accountingConfiguration';

@Module({ name: moduleName, namespaced: true })
export class AccountingConfigurationModule extends CrudList<AccountingConfiguration> {
  crudEndpoint: string = '/documents-api/accounting/configuration';

  // Getters
}

export default AccountingConfigurationModule;
