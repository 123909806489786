<template>
  <span v-if="triState && value === null" class="text-secondary">
    <i class="material-icons">remove</i>
  </span>
  <span v-else-if="value" :class="colorTrue">
    <i class="material-icons">check</i>
  </span>
  <span :class="colorFalse" v-else>
    <i class="material-icons">close</i>
  </span>
</template>

<script>
export default {
  name: 'bool-indicator',
  props: {
    value: Boolean,
    triState: {
      type: Boolean,
      default: true,
    },
    reverseColors: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return this.reverseColors
      ? {
          colorTrue: 'text-danger',
          colorFalse: 'text-success',
        }
      : {
          colorFalse: 'text-danger',
          colorTrue: 'text-success',
        };
  },
};
</script>
