import { ContactProfileEnum } from '@gid/models/dist/entities/contact-profile-enum';

export default {
  permissions: [ContactProfileEnum.GID],
  title: {
    singular: 'Job Import',
    plural: 'Job Imports',
  },
  basePath: 'job-import',
  fields: [
    {
      name: 'id',
      type: 'int',
      primary: true,
    },
    {
      label: 'Created At',
      name: 'timeStamp',
      type: 'date',
    },
    {
      label: 'Import ID',
      name: 'importId',
      type: 'string',
    },
    {
      label: 'Successful',
      name: 'importSuccessful',
      type: 'boolean',
    },
    {
      label: 'Processed',
      name: 'processed',
      type: 'boolean',
    },
  ],
  actions: [
    {
      icon: 'search',
      type: 'detail',
      extra: {
        fields: [
          {
            label: 'Job Data',
            name: 'jobData',
            type: 'json', // maybe can be added...
          },
        ],
        actions: [
          {
            icon: 'save',
            type: 'recreateJob',
            label: 'Force Job Creation',
            loading: false,
          },
        ],
      },
    },
  ],
};
