import moment from 'moment';
import { Module } from 'vuex-module-decorators';
import { AccountingBankAccount } from '@gid/models';
import {
  CrudList,
  CrudListActionsEnum,
} from '@gid/vue-common/store/shared/crud-list.module';

export enum _AccountingBankAccountsActionsEnum {}
// Add specific actions here

export const AccountingBankAccountsActionsEnum = {
  ...CrudListActionsEnum,
  ..._AccountingBankAccountsActionsEnum,
};

export const moduleName = 'accountingBankAccounts';

@Module({ name: moduleName, namespaced: true })
export class AccountingBankAccountsModule extends CrudList<AccountingBankAccount> {
  entityConstructorName = 'AccountingBankAccount';
  crudEndpoint: string = '/documents-api/accounting/bank-accounts';

  // Getters
}
export default AccountingBankAccountsModule;
