import moment from 'moment';
import Vue from 'vue';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import {
  AccountingDocument,
  AccountingDocumentStatusEnum,
  GeneralInvoicingTargetEnum,
  JobTypeEnum,
} from '@gid/models';
import {
  CrudList,
  CrudListActionsEnum,
  CrudListFilters,
} from '@gid/vue-common/store/shared/crud-list.module';
import { ContactProfileEnum } from '@gid/models/dist/entities/contact-profile-enum';

export enum _AccountingDraftsActionsEnum {}
// Add job view specific actions/mutations here

export const AccountingDraftsActionsEnum = {
  ...CrudListActionsEnum,
  ..._AccountingDraftsActionsEnum,
};

export interface DraftFiltersAccountOption {
  id: string;
  name: string;
}

export interface DraftFiltersOpportunityOption {
  id: string;
  name: string;
}

export interface DraftFiltersJobOption {
  id: string;
  name: string;
  createdDate: string;
}

type _BrandVariant =
  | GeneralInvoicingTargetEnum.BRAND
  | typeof GeneralInvoicingTargetEnum.BRAND_1
  | typeof GeneralInvoicingTargetEnum.BRAND_2;
type BrandVariant = Extract<GeneralInvoicingTargetEnum, _BrandVariant> | 'any';

type _CustomerVariant =
  | GeneralInvoicingTargetEnum.CUSTOMER
  | typeof GeneralInvoicingTargetEnum.CUSTOMER_SECOND;
type CustomerVariant =
  | Extract<GeneralInvoicingTargetEnum, _CustomerVariant>
  | 'any';

export interface AccountingDraftsFilters extends CrudListFilters {
  filterType: 'dateRange' | 'jobList';

  dateFrom: string;
  dateTo: string;
  targetBrand: boolean;
  targetBrandVariant: BrandVariant;
  targetSupplier: boolean;
  targetAffiliate: boolean;
  targetPartner: boolean;
  targetCustomer: boolean;
  targetCustomerVariant: CustomerVariant;

  selectedBrands: DraftFiltersAccountOption[];
  selectedSuppliers: DraftFiltersAccountOption[];
  selectedAffiliates: DraftFiltersAccountOption[];
  selectedPartners: DraftFiltersAccountOption[];
  selectedCustomers: DraftFiltersAccountOption[];

  selectedJobs: DraftFiltersJobOption[];
  selectedJobTypes: JobTypeEnum[];
  selectedOpportunities: DraftFiltersOpportunityOption[];

  preInvoicing?: boolean;
}

export const moduleName = 'accountingDrafts';

@Module({ name: moduleName, namespaced: true })
export class AccountingDraftsModule extends CrudList<AccountingDocument> {
  entityConstructorName = AccountingDocument.name;
  crudEndpoint: string = '/documents-api/accounting/drafts';
  filters: AccountingDraftsFilters = {
    skipPersist: false,
    filterType: 'jobList',
    dateFrom: moment().startOf('month').toISOString(),
    dateTo: moment().endOf('day').toISOString(),

    targetBrand: false,
    targetBrandVariant: 'any',
    targetSupplier: false,
    targetAffiliate: false,
    targetPartner: false,
    targetCustomer: false,
    targetCustomerVariant: 'any',

    selectedBrands: [],
    selectedSuppliers: [],
    selectedAffiliates: [],
    selectedPartners: [],
    selectedCustomers: [],

    selectedJobs: [],
    selectedJobTypes: [],
    selectedOpportunities: [],

    preInvoicing: false,
  };

  @Mutation
  selectionSetAllG({ newState, getters }) {
    if (newState) {
      this.selected = Object.fromEntries(
        Object.entries(this.items).filter(([id, item]) =>
          getters.isSelectable(id),
        ),
      );
    } else {
      this.selected = {};
    }
  }

  @Mutation
  toggleSelectionByIdG({ id, getters }) {
    if (!getters.isSelectable(id)) {
      return;
    }
    const isSelected = id in this.selected;
    if (isSelected) {
      Vue.delete(this.selected, id);
    } else {
      Vue.set(this.selected, id, this.items[`id-${id}`]);
    }
  }

  // Getters
  get isPowerUser() {
    return this.context.rootGetters.contactProfiles?.includes(
      ContactProfileEnum.FINANCE_POWER_USER,
    );
  }

  get isProductPowerUser() {
    return this.context.rootGetters.contactProfiles?.includes(
      ContactProfileEnum.PRODUCT_POWER_USER,
    );
  }

  get isSelectable(): (id: string) => boolean {
    return (id: string) => {
      const item = this.items[`id-${id}`];
      return (
        (this.isPowerUser || !item.isDocumentCreationDelayed) &&
        (this.isPowerUser || !item.hasReclamationInProject)
      );
    };
  }

  get selectedCountByType(): { [key: string]: number } {
    return Object.values(this.selected).reduce(
      (r, item) => ({
        ...r,
        [item.type]: (r[item.type] ?? 0) + 1,
      }),
      {},
    );
  }

  //
  @Action
  [CrudListActionsEnum.ITEMS_SELECTION_SET_ALL]({ newState }) {
    this.context.commit('selectionSetAllG', {
      newState,
      getters: this.context.getters,
    });
  }

  @Action
  [CrudListActionsEnum.ITEMS_SELECTION_TOGGLE]({ id }) {
    this.context.commit('toggleSelectionByIdG', {
      id,
      getters: this.context.getters,
    });
  }
}

export default AccountingDraftsModule;
