import { ContactProfileEnum } from '@gid/models/dist/entities/contact-profile-enum';

export default {
  permissions: [ContactProfileEnum.PRODUCT],
  title: {
    singular: 'Brand Asset',
    plural: 'Brand Assets',
  },
  basePath: 'brand-assets',
  fields: [
    {
      name: 'id',
      type: 'int',
      primary: true,
    },
    {
      label: 'Brand',
      type: 'select',
      name: 'id',
      relation: {
        name: 'brand',
        type: 'one',
      },
      display: {
        name: 'name',
        endpoint: 'brand',
      },
    },
    {
      label: 'Assets For Apps',
      type: 'img',
      name: 'forApps',
    },
    { label: 'Assets For Email', type: 'img', name: 'forEmail' },
  ],
};
