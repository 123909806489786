import { Module } from 'vuex-module-decorators';
import { JobView } from '@gid/models';
import {
  CrudList,
  CrudListFilters,
} from '@gid/vue-common/store/shared/crud-list.module';

export interface FilterArrayOption {
  id: string;
  name: string;
}

export interface ProductOrderPendingFilters extends CrudListFilters {
  search: string;
  opportunity: FilterArrayOption[];
  supplier: FilterArrayOption[];
  partner: FilterArrayOption[];
  product: FilterArrayOption[];
}

export const moduleName = 'productOrdersPending';

@Module({ name: moduleName, namespaced: true })
export default class ProductOrdersPendingModule extends CrudList<JobView> {
  crudEndpoint: string = '/documents-api/product-orders-pending';
  filters: ProductOrderPendingFilters = {
    skipPersist: false,
    search: '',
    opportunity: [],
    supplier: [],
    partner: [],
    product: [],
  };
}
