import moment from 'moment';
import { Module } from 'vuex-module-decorators';
import { PaymentRecord } from '@gid/models';
import {
  CrudList,
  CrudListActionsEnum,
  CrudListFilters,
} from '@gid/vue-common/store/shared/crud-list.module';

export enum _PaymentRecordsActionsEnum {}
// Add specific actions here

export interface PaymentRecordFilters extends CrudListFilters {
  search: string;
  dateFrom: string;
  dateTo: string;
  linkedDocuments: null | 'with' | 'without';
}

export const PaymentRecordsActionsEnum = {
  ...CrudListActionsEnum,
  ..._PaymentRecordsActionsEnum,
};

export const moduleName = 'paymentRecords';

@Module({ name: moduleName, namespaced: true })
export class PaymentRecordsModule extends CrudList<PaymentRecord> {
  crudEndpoint: string = '/documents-api/accounting/payments';
  filters: PaymentRecordFilters = {
    skipPersist: false,
    search: '',
    dateFrom: moment().startOf('month').toISOString(),
    dateTo: moment().endOf('day').toISOString(),
    linkedDocuments: null,
  };

  // Getters
}

export default PaymentRecordsModule;
