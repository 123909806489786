import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import { ContactProfileEnum } from '@gid/models/dist/entities/contact-profile-enum';
import Entities from '../entities';
import { DataUiBlueprints } from '@gid/models';

Vue.use(Router);

/* Routes definition specification
 *
 * For each route access is defined via the meta setting
 * `auth`, which could contain `contactProfiles`, `accountProfiles` and `accountStatuses`.
 * If `auth` is present, the user must be authenticated.
 * `contactProfiles` recognizes the following values:
 * - true: requires authenticated user without contact profile restrictions
 * - array of strings: requires authenticated with one of the specified contact profiles
 */

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        auth: {
          contactProfiles: true,
        },
      },
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/tools',
      name: 'tools',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.CUSTOMER_MANAGEMENT,
            ContactProfileEnum.PARTNER_ONBOARDING,
            ContactProfileEnum.BRAND_MANAGEMENT,
            ContactProfileEnum.GID,
            ContactProfileEnum.PARTNER_MANAGEMENT,
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      redirect: { name: 'email-tester' },
    },
    {
      path: '/email-queues',
      name: 'email-queues',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.CUSTOMER_MANAGEMENT,
            ContactProfileEnum.PARTNER_ONBOARDING,
            ContactProfileEnum.BRAND_MANAGEMENT,
            ContactProfileEnum.GID,
            ContactProfileEnum.PARTNER_MANAGEMENT,
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/tools/QueueDashboard.vue'),
      props: { route: '/email-api/queues-monitor' },
    },
    {
      path: '/jobs-service-queues',
      name: 'jobs-service-queues',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.CUSTOMER_MANAGEMENT,
            ContactProfileEnum.PARTNER_ONBOARDING,
            ContactProfileEnum.BRAND_MANAGEMENT,
            ContactProfileEnum.GID,
            ContactProfileEnum.PARTNER_MANAGEMENT,
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/tools/QueueDashboard.vue'),
      props: { route: '/jobs-api/queues-monitor' },
    },
    {
      path: '/chat-view/:assistant_id',
      name: 'chat-view',
      component: () => import('@/views/chat-view/chat.vue'),
    },

    {
      path: '/crud-queues',
      name: 'crud-queues',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.CUSTOMER_MANAGEMENT,
            ContactProfileEnum.PARTNER_ONBOARDING,
            ContactProfileEnum.BRAND_MANAGEMENT,
            ContactProfileEnum.GID,
            ContactProfileEnum.PARTNER_MANAGEMENT,
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/tools/QueueDashboard.vue'),
      props: { route: '/data-api/queues-monitor' },
    },
    {
      path: '/documents-service-queues',
      name: 'documents-service-queues',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.CUSTOMER_MANAGEMENT,
            ContactProfileEnum.PARTNER_ONBOARDING,
            ContactProfileEnum.BRAND_MANAGEMENT,
            ContactProfileEnum.GID,
            ContactProfileEnum.PARTNER_MANAGEMENT,
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/tools/QueueDashboard.vue'),
      props: { route: '/documents-api/queues-monitor' },
    },
    {
      path: '/email-tester',
      name: 'email-tester',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.CUSTOMER_MANAGEMENT,
            ContactProfileEnum.PARTNER_ONBOARDING,
            ContactProfileEnum.BRAND_MANAGEMENT,
            ContactProfileEnum.GID,
            ContactProfileEnum.PARTNER_MANAGEMENT,
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/tools/EmailTester.vue'),
    },
    {
      path: '/jobs/create',
      name: 'jobs-create',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.CUSTOMER_MANAGEMENT,
            ContactProfileEnum.PARTNER_ONBOARDING,
            ContactProfileEnum.BRAND_MANAGEMENT,
            ContactProfileEnum.GID,
            ContactProfileEnum.PARTNER_MANAGEMENT,
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@gid/vue-common/components/JobCreate.vue'),
      props: { context: 'admin' },
    },
    {
      path: '/jobs/create-alternative',
      name: 'jobs-create-alternative',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.CUSTOMER_MANAGEMENT,
            ContactProfileEnum.PARTNER_ONBOARDING,
            ContactProfileEnum.BRAND_MANAGEMENT,
            ContactProfileEnum.GID,
            ContactProfileEnum.PARTNER_MANAGEMENT,
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@gid/vue-common/components/JobFollowup.vue'),
      props: { context: 'admin', alternative: true },
    },
    {
      path: '/jobs/followup',
      name: 'job-followup',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.CUSTOMER_MANAGEMENT,
            ContactProfileEnum.PARTNER_ONBOARDING,
            ContactProfileEnum.BRAND_MANAGEMENT,
            ContactProfileEnum.GID,
            ContactProfileEnum.PARTNER_MANAGEMENT,
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@gid/vue-common/components/JobFollowup.vue'),
      props: { context: 'admin' },
    },
    {
      path: '/jobs/reclamation',
      name: 'job-reclamation',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.CUSTOMER_MANAGEMENT,
            ContactProfileEnum.PARTNER_ONBOARDING,
            ContactProfileEnum.BRAND_MANAGEMENT,
            ContactProfileEnum.GID,
            ContactProfileEnum.PARTNER_MANAGEMENT,
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@gid/vue-common/components/JobFollowup.vue'),
      props: { context: 'admin', reclamation: true },
    },
    {
      path: '/jobs/:job_id?',
      name: 'jobs',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.CUSTOMER_MANAGEMENT,
            ContactProfileEnum.PARTNER_ONBOARDING,
            ContactProfileEnum.BRAND_MANAGEMENT,
            ContactProfileEnum.GID,
            ContactProfileEnum.PARTNER_MANAGEMENT,
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/jobs/Jobs.vue'),
      props: true,
    },
    {
      path: '/partners',
      name: 'partners',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.CUSTOMER_MANAGEMENT,
            ContactProfileEnum.PARTNER_ONBOARDING,
            ContactProfileEnum.BRAND_MANAGEMENT,
            ContactProfileEnum.GID,
            ContactProfileEnum.PARTNER_MANAGEMENT,
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/partners/Partners.vue'),
    },
    {
      path: '/accounting',
      name: 'accounting',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      redirect: { name: 'accounting-documents' },
    },
    {
      path: '/accounting/drafts',
      name: 'accounting-drafts',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/accounting/Drafts.vue'),
    },
    {
      path: '/accounting/recheck-drafts',
      name: 'accounting-recheck-drafts',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/accounting/RecheckDrafts.vue'),
    },
    {
      path: '/accounting/documents/:documentId?',
      name: 'accounting-documents',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
            ContactProfileEnum.EXTERNAL_TAX_OFFICE,
          ],
        },
      },
      component: () => import('@/views/accounting/Documents.vue'),
      props: true,
    },
    {
      path: '/accounting/payments/:paymentId?',
      name: 'accounting-payments',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
            ContactProfileEnum.EXTERNAL_TAX_OFFICE,
          ],
        },
      },
      component: () => import('@/views/accounting/Payments.vue'),
      props: true,
    },
    {
      path: '/accounting/documents-balances',
      name: 'documents-balances',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/accounting/DocumentsBalances.vue'),
    },
    {
      path: '/accounting/configuration',
      name: 'accounting-configuration',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/accounting/Configuration.vue'),
    },
    {
      path: '/accounting/exports',
      name: 'accounting-exports',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/accounting/Exports.vue'),
    },
    {
      path: '/order-intake-forms/questions-new',
      name: 'questions-new',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/order-intake-forms/QuestionsNew.vue'),
    },
    {
      path: '/data',
      name: 'data',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.CUSTOMER_MANAGEMENT,
            ContactProfileEnum.PARTNER_ONBOARDING,
            ContactProfileEnum.BRAND_MANAGEMENT,
            ContactProfileEnum.GID,
            ContactProfileEnum.PARTNER_MANAGEMENT,
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/data/Home.vue'),
    },
    {
      path: '/data/:entity',
      name: 'data-entity',
      meta: {
        authContactProfiles: (route, profiles) => {
          const entity = Entities[route.params.entity];
          if (!entity?.permissions) {
            return false;
          }
          return entity.permissions.some((p) => profiles.includes(p));
        },
      },
      component: () => import('@/components/crud/Page.vue'),
      props: true,
    },
    {
      path: '/data-ui/:entity/:entityIdsList?',
      name: 'data-ui-entity',
      meta: {
        authContactProfiles: (route, profiles) => {
          const blueprint = DataUiBlueprints.get(route.params.entity);
          return blueprint?.permissions.read?.some((p) => profiles.includes(p));
        },
      },
      component: () => import('@/components/data-ui/CrudEntityEditor.vue'),
      props: true,
    },
    {
      path: '/rating-admin',
      name: 'rating-admin',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () => import('@/views/integrations/Rating.vue'),
    },
    {
      path: '/order-intake-forms-admin',
      name: 'order-intake-forms-admin',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      component: () =>
        import('@/views/integrations/OrderIntakeFormsBackoffice.vue'),
    },
    {
      path: '/product-orders',
      name: 'product-orders',
      meta: {
        auth: {
          contactProfiles: [
            ContactProfileEnum.FINANCE,
            ContactProfileEnum.FINANCE_POWER_USER,
            ContactProfileEnum.PRODUCT,
            ContactProfileEnum.PRODUCT_POWER_USER,
          ],
        },
      },
      redirect: { name: 'product-orders-pending' },
      component: () => import('@/views/product-orders/ProductOrders.vue'),
      children: [
        {
          path: 'pending',
          name: 'product-orders-pending',
          meta: {
            auth: {
              contactProfiles: [
                ContactProfileEnum.GID,
                ContactProfileEnum.FINANCE,
                ContactProfileEnum.FINANCE_POWER_USER,
                ContactProfileEnum.PRODUCT,
                ContactProfileEnum.PRODUCT_POWER_USER,
              ],
            },
          },
          component: () =>
            import('@/views/product-orders/ProductOrdersPending.vue'),
        },
        {
          path: 'ordered',
          name: 'product-orders-ordered',
          meta: {
            auth: {
              contactProfiles: [
                ContactProfileEnum.GID,
                ContactProfileEnum.FINANCE,
                ContactProfileEnum.FINANCE_POWER_USER,
                ContactProfileEnum.PRODUCT,
                ContactProfileEnum.PRODUCT_POWER_USER,
              ],
            },
          },
          component: () =>
            import('@/views/product-orders/ProductOrdersOrdered.vue'),
        },
      ],
    },
  ],
});
