import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import _ from 'lodash';

import { storeCommon, storeCommonInit } from '@gid/vue-common/store';
import opportunities_module from '@gid/vue-common/store/opportunities.module';
import partners_module from './partners.module';
import {
  AccountingDraftsModule,
  moduleName as accountingDraftsModuleName,
} from './accounting-drafts.module';
import {
  AccountingDocumentsModule,
  moduleName as accountingDocumentsModuleName,
} from './accounting-documents.module';
import {
  AccountingConfigurationModule,
  moduleName as accountingConfigurationModuleName,
} from './accounting-configuration.module';
import {
  AccountingBankAccountsModule,
  moduleName as accountingBankAccountsModuleName,
} from './accounting-bank-accounts.module';
import {
  PaymentRecordsModule,
  moduleName as paymentRecordsModuleName,
} from './accounting-payments.module';
import {
  DocumentsBalanceModule,
  moduleName as documentsBalanceModuleName,
} from './documents-balance.module';
import {
  AccountingRecheckDraftsModule,
  moduleName as accountingRecheckDraftsModuleName,
} from './accounting-recheck-drafts.module';
import ProductOrdersPendingModule, {
  moduleName as productOrdersPendingModuleName,
} from './product-orders-pending.module';
import ProductOrdersModule, {
  moduleName as productOrdersModuleName,
} from './product-orders.module';

Vue.use(Vuex);

function reducer(state, paths) {
  return Array.isArray(paths)
    ? paths.reduce(function (subState, path) {
        const v = _.get(state, path);
        _.set(
          subState,
          path,
          v.skipPersist ? { skipPersist: v.skipPersist } : v,
        );
        return subState;
      }, {})
    : state;
}

const store = new Vuex.Store({
  modules: {
    ...storeCommon.modules,
    opportunities: opportunities_module,
    partners: partners_module,
    [accountingDraftsModuleName]: AccountingDraftsModule,
    [accountingDocumentsModuleName]: AccountingDocumentsModule,
    [accountingConfigurationModuleName]: AccountingConfigurationModule,
    [accountingBankAccountsModuleName]: AccountingBankAccountsModule,
    [paymentRecordsModuleName]: PaymentRecordsModule,
    [documentsBalanceModuleName]: DocumentsBalanceModule,
    [accountingRecheckDraftsModuleName]: AccountingRecheckDraftsModule,
    [productOrdersModuleName]: ProductOrdersModule,
    [productOrdersPendingModuleName]: ProductOrdersPendingModule,
  },
  plugins: [
    ...storeCommon.plugins,
    createPersistedState({
      reducer,
      paths: [
        'jobs.filters',
        'partners',
        `${paymentRecordsModuleName}.filters`,
        `${documentsBalanceModuleName}.filters`,
        `${accountingDraftsModuleName}.filters`,
        `${accountingDocumentsModuleName}.filters`,
        `${accountingRecheckDraftsModuleName}.filters`,
        `${productOrdersPendingModuleName}.filters`,
        `${productOrdersModuleName}.filters`,
      ],
    }),
  ],
});

storeCommonInit(store);

export default store;
