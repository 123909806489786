import { ContactProfileEnum } from '@gid/models/dist/entities/contact-profile-enum';

export default {
  permissions: [ContactProfileEnum.PRODUCT],
  sort: {
    name: 'createdAt',
    direction: 'DESC',
  },
  title: {
    singular: 'Url',
    plural: 'Urls',
  },
  basePath: 'url',
  fields: [
    {
      name: 'id',
      type: 'uuid',
      primary: true,
      readonly: true,
    },
    {
      name: 'name',
      type: 'string',
    },
    {
      name: 'redirectTo',
      type: 'string',
    },
    {
      name: 'params',
      type: 'string',
    },
  ],
};
