import { ContactProfileEnum } from '@gid/models/dist/entities/contact-profile-enum';

export default {
  permissions: [ContactProfileEnum.PRODUCT],
  title: {
    singular: 'Template',
    plural: 'Templates',
  },
  basePath: 'template',
  fields: [
    {
      name: 'id',
      type: 'int',
      primary: true,
    },
    {
      name: 'path',
      type: 'string',
    },
    {
      name: 'status',
      type: 'select',
      default: 'inactive',
      display: {
        selectOptions: ['active', 'inactive'],
        disableEmpty: true,
      },
    },
    {
      name: 'content',
      type: 'code',
      i18n: true,
      hideInList: true,
      preview: true,
      relation: {
        name: 'templateLangs',
        type: 'many',
      },
      display: {
        language: 'gid-jinja2',
      },
    },
    {
      name: 'context',
      type: 'json',
      hideInList: true,
    },
  ],
};
