import { ContactProfileEnum } from '@gid/models/dist/entities/contact-profile-enum';

export default {
  permissions: [ContactProfileEnum.PRODUCT],
  sort: {
    name: 'createdAt',
    direction: 'DESC',
  },
  title: {
    singular: 'Identification',
    plural: 'Identification',
  },
  basePath: 'identification',
  fields: [
    {
      name: 'id',
      type: 'int',
      primary: true,
    },
    {
      label: 'Form',
      name: 'id',
      type: 'select',
      relation: {
        name: 'form',
        type: 'one',
      },
      display: {
        name: 'name',
        endpoint: 'form',
      },
    },
    {
      label: 'Opportunity',
      name: 'id',
      type: 'select',
      relation: {
        name: 'opportunity',
        type: 'one',
      },
      display: {
        name: 'name',
        endpoint: 'opportunity',
      },
    },
    {
      name: 'domain',
      type: 'string',
    },
    {
      name: 'code',
      type: 'string',
    },
    {
      name: 'cardNumber',
      type: 'string',
    },
  ],
};
