import { ContactProfileEnum } from '@gid/models/dist/entities/contact-profile-enum';

export default {
  permissions: [ContactProfileEnum.PRODUCT],
  title: {
    singular: 'Carrier',
    plural: 'Carriers',
  },
  basePath: 'carrier',
  fields: [
    {
      name: 'id',
      type: 'int',
      primary: true,
    },
    {
      name: 'name',
      type: 'string',
    },
    {
      name: 'urlPattern',
      label: 'URL Pattern',
      type: 'string',
      emptyToNull: true,
      description: 'Example: https://tracking.com/?id={ID}',
    },
  ],
};
