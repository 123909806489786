
import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import ProductOrdersPendingModule from '../../store/product-orders-pending.module';

@Component
export default class ProductOrdersPending extends Vue {
  // Computed
  get store(): ProductOrdersPendingModule {
    return getModule(ProductOrdersPendingModule, this.$store);
  }

  created() {
    this.store.ITEMS_FETCH({
      query: {
        page: 1,
        limit: 1,
      },
    });
  }

  get count() {
    return this.store.pagination.totalRows;
  }
}
