<template>
  <b-modal
    id="simulate-user-modal"
    :visible="visible"
    @change="handleChange"
    centered
    title="User Simulation Tool"
    ok-only
    :ok-disabled="loading"
    @ok="loginAsUser"
    @hidden="email = null"
  >
    <template #modal-ok>
      <b-spinner v-if="loading" small></b-spinner>
      Login as...
    </template>
    <p>Please enter the email address of the contact you wish to simulate.</p>
    <b-form-input
      v-model="email"
      placeholder="john.doe@getitdone.rocks"
    ></b-form-input>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import { appUrl } from '@gid/vue-common/app-settings/appUrls';
import { RequestQueryBuilder } from '@dataui/crud-request';

export default {
  props: {
    visible: Boolean,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  data() {
    return {
      email: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['environment']),
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
    async loginAsUser(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.loading = true;

      try {
        const params = RequestQueryBuilder.create({
          search: { email: { $eq: this.email } },
        });
        const res = await axios.get(`/data-api/contact?${params.query()}`);
        const contact = res.data[0];
        const userRole = contact?.account?.role;

        const {
          data: { refresh_token },
        } = await axios.post('/api/admin/refresh-token-for', {
          email: contact.email,
          role: userRole,
        });

        const url = appUrl(userRole, `/login?token=${refresh_token}`);

        window.open(url, '_blank');

        this.$nextTick(() => {
          this.$bvModal.hide('simulate-user-modal');
        });
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(`Could not login as user ${this.email}`, {
          title: 'Error',
          variant: 'danger',
          solid: true,
          noAutoHide: true,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
