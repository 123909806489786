import moment from 'moment';
import { Module } from 'vuex-module-decorators';
import { DocumentsBalance } from '@gid/models';
import {
  CrudList,
  CrudListActionsEnum,
  CrudListFilters,
} from '@gid/vue-common/store/shared/crud-list.module';
import { Pagination } from '@gid/vue-common/store/shared/pagination';

export interface DocumentsBalanceFilters extends CrudListFilters {
  search: string;
  targetType: string;
  dateFrom: string;
  dateTo: string;
}

export enum _DocumentsBalanceActionsEnum {}
// Add job view specific actions/mutations here

export const DocumentsBalanceActionsEnum = {
  ...CrudListActionsEnum,
  ..._DocumentsBalanceActionsEnum,
};

export const moduleName = 'accountingDocumentsBalance';

@Module({ name: moduleName, namespaced: true })
export class DocumentsBalanceModule extends CrudList<DocumentsBalance> {
  crudEndpoint: string = '/documents-api/accounting/documents-balances';
  pagination: Pagination = {
    skipPersist: false,
    totalRows: 0,
    perPage: 10,
    currentPage: 1,
    currentPageCount: 0,
    numberOfPages: 1,
    sortBy: 'mappedDocsMinusPaid',
    sortDir: undefined,
  };
  filters: DocumentsBalanceFilters = {
    skipPersist: false,

    search: '',
    targetType: '',
    dateFrom: moment().startOf('month').toISOString(),
    dateTo: moment().endOf('day').toISOString(),
  };

  // Getters
}

export default DocumentsBalanceModule;
