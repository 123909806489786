<template>
  <div class="my-3 text-center">
    <template v-if="state != 'success'">
      <b-alert v-if="state == 'error'" variant="danger" class="text-left" show>
        <h4 class="alert-heading">{{ $t('_errors.general.title') }}:</h4>
        <p>{{ error.message }}</p>
        <b-collapse id="error-details" class="my-2">
          <b-card>
            <pre>{{ error.details }}</pre>
          </b-card>
        </b-collapse>
        <b-btn v-b-toggle.error-details>
          {{ $t('login.buttons.error-details') }}
        </b-btn>
      </b-alert>
      <p class="lead">{{ $t('login.description') }}:</p>
      <b-form inline class="my-2 justify-content-center" @submit.prevent>
        <b-input-group>
          <b-form-input v-model="email" :placeholder="$t('email')" />
          <b-input-group-append>
            <b-button variant="primary" type="submit" @click="login">
              {{ $t('login.buttons.login') }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
    </template>
    <p v-else class="lead">{{ $t('login.success') }}</p>
  </div>
</template>

<script>
import {
  LOGIN,
  GENERATE_ACCESS_TOKEN,
} from '@gid/vue-common/store/auth.module/types';

export default {
  data() {
    return {
      email: null,
      state: 'login',
      error: null,
    };
  },
  methods: {
    login() {
      const path = this.$router.resolve({ name: 'login' }).href;
      const absoluteUrl = new URL(path, window.location.href).href;
      this.$store
        .dispatch(LOGIN, {
          email: this.email,
          role: 'admin',
          link: absoluteUrl,
        })
        .then(() => {
          this.state = 'success';
        })
        .catch((error) => {
          this.state = 'error';
          const err = { details: error };
          if (error.response) {
            err.message = error.response.data.msg || error.response.data;
          } else if (error.request) {
            err.message = this.$t('_errors.network.details');
          } else {
            err.message = error.message;
          }
          this.error = err;
        });
    },
  },
  created() {
    if (this.$route.query.token) {
      this.$store
        .dispatch(GENERATE_ACCESS_TOKEN, {
          refresh_token: this.$route.query.token,
        })
        .then(() => {
          this.$router.push({ name: 'home' });
        })
        .catch((error) => {
          this.state = 'error';
          const err = { details: error };
          if (error.response) {
            err.message = error.response.data.msg || error.response.data;
          } else if (error.request) {
            err.message = this.$t('_errors.network.details');
          } else {
            err.message = error.message;
          }
          this.error = err;
        });
    }
  },
};
</script>
