import moment from 'moment';
import Vue from 'vue';
import { Module, Mutation } from 'vuex-module-decorators';
import {
  AccountingDocument,
  AccountingDocumentStatusEnum,
  JobStatusEnum,
} from '@gid/models';
import {
  CrudList,
  CrudListActionsEnum,
  CrudListFilters,
} from '@gid/vue-common/store/shared/crud-list.module';
import { AccountingDraftsFilters } from './accounting-drafts.module';

export {
  DraftFiltersAccountOption,
  DraftFiltersJobOption,
} from './accounting-drafts.module';

export enum _AccountingRecheckDraftsActionsEnum {}
// Add job view specific actions/mutations here

export const AccountingRecheckDraftsActionsEnum = {
  ...CrudListActionsEnum,
  ..._AccountingRecheckDraftsActionsEnum,
};

export interface AccountingRecheckDraftsFilters
  extends AccountingDraftsFilters {
  jobStatuses: JobStatusOption[];
}

export interface JobStatusOption {
  label: string;
  value: string;
}

export const moduleName = 'accountingRecheckDrafts';

@Module({ name: moduleName, namespaced: true })
export class AccountingRecheckDraftsModule extends CrudList<AccountingDocument> {
  crudEndpoint: string = '/documents-api/accounting/drafts';
  filters: AccountingRecheckDraftsFilters = {
    skipPersist: false,
    filterType: 'jobList',
    dateFrom: moment().startOf('month').toISOString(),
    dateTo: moment().endOf('day').toISOString(),

    targetBrand: false,
    targetBrandVariant: 'any',
    targetSupplier: false,
    targetAffiliate: false,
    targetPartner: false,
    targetCustomer: false,
    targetCustomerVariant: 'any',

    selectedBrands: [],
    selectedSuppliers: [],
    selectedAffiliates: [],
    selectedPartners: [],
    selectedCustomers: [],

    selectedJobs: [],
    selectedJobTypes: [],
    selectedOpportunities: [],
    jobStatuses: [
      {
        label: '',
        value: JobStatusEnum.INVOICE_SENT,
      },
    ],
  };
}

export default AccountingRecheckDraftsModule;
