import 'reflect-metadata';
import moment from '@gid/vue-common/app-settings/moment';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueMoment from 'vue-moment';
import * as Sentry from '@sentry/browser';

import App from './App.vue';

import messages from './i18n/messages';
import router from './router';
import store from './store';
import {
  initVeeValidateRulesWithI18n,
  setVeeValidateLocale,
} from './app/components';

import './app/filters';

import {
  PURGE_AUTH,
  STORE_USER_DATA,
} from '@gid/vue-common/store/auth.module/types';

import { e2e } from '@gid/vue-common/app-settings/customDirectives';
import { adjustTypeOrmTypesForSqlite } from '@gid/vue-common/app-settings/typeorm-util';
import { setupGoogleAnalytics } from '@gid/vue-common/app-settings/googleAnalytics';
import '@gid/vue-common/app-settings/registerServiceWorker';
import { createConnection } from 'typeorm';
import { ALL_ENTITIES, DataUiBlueprints } from '@gid/models';
import { getEnv } from '@gid/vue-common/utils';

import '@/assets/scss/styles.scss';

Vue.use(VueI18n);
Vue.use(VueMoment, { moment });

const defaultLocale = store.getters.locale;
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages,
});
moment.locale(defaultLocale);
setVeeValidateLocale(defaultLocale);
initVeeValidateRulesWithI18n(i18n);

router.beforeEach((to, from, next) => {
  if (!store.getters.isAuthenticated && to.name != 'login') {
    next({ name: 'login' });
  } else {
    next();
  }
});

store.subscribe(({ type }) => {
  if (type === PURGE_AUTH) {
    // Go to login page on logout
    router.push({ name: 'login' });
  } else if (type === STORE_USER_DATA) {
    // Set locales in used components
    const locale = store.getters.locale;
    i18n.locale = locale;
    moment.locale(locale);
    setVeeValidateLocale(locale);
  }
});

Vue.directive('e2e', e2e);

setupGoogleAnalytics(router);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

// register a bogus connection so we use the metadata
adjustTypeOrmTypesForSqlite();
createConnection({
  name: 'default',
  type: 'sqljs',
  entities: Object.values(ALL_ENTITIES),
}).then((connection) => {
  DataUiBlueprints.init('/data-api/', connection);
});

if (getEnv('NODE_ENV') == 'production') {
  Sentry.init({
    dsn: 'https://8d59cfd213854345aba73ce3cad681f1@sentry.io/1384787',
    environment: getEnv('GID_ENV') ?? getEnv('VUE_APP_ENV'),
    release: getEnv('VUE_APP_SOURCE_VERSION'),
    integrations: [new Sentry.Integrations.Vue({ Vue })],
  });
}
